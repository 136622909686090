@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Cormorant+Infant:wght@300&family=Mate+SC&family=Quintessential&family=UnifrakturCook:wght@700&display=swap");
/* 
@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  src: local("times"), url("times.tff") format("tff");
} */

.App {
  text-align: center;
  /* font-family: "Mate SC", serif; */

  /* font-family: "Times New Roman", sans-serif; */
  /* font-family: "UnifrakturCook", cursive; */
  font-family: "Cormorant Garamond", serif;
  font-weight: normal;
  color: #595858;
}

/* ===========================
TYPOGRAPHY
=========================== */

h1 {
  /* font-family: "UnifrakturCook", cursive; */
  /* font-family: "Mate SC", serif; */
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  color: black;
  line-height: 125%;
  font-weight: 400;
  margin: 0;
}

h2 {
  /* font-family: "Quintessential", cursive; */
  /* font-family: "Cormorant Infant", serif; */
  /* font-family: "Quintessential", cursive; */
  font-family: "Cormorant Garamond", serif;
  font-size: 16px;
  line-height: 142%;
  font-weight: lighter;
}

h3 {
  font-size: 20px;
  line-height: 125%;
  /* font-weight: 400; */
}

h4 {
  font-size: 16px;
  line-height: 175%;
}

h5 {
  font-size: 16px;
  line-height: 160%;
  font-weight: normal;
  margin: 0;
}

h6 {
  font-size: 16px;
  line-height: 200%;
}

.caption {
  font-size: 16px;
  line-height: 167%;
}

p {
  font-family: "Cormorant Garamond", serif;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
}

.title {
  margin-bottom: 5%;
}

/* ===========================
Hover
=========================== */
.hover:hover {
  color: black;
}
/* ===========================
BUTTONS
=========================== */

button {
  font-family: "Times New Roman", Times, serif;
  width: 200px;
  padding: 14px;
  background-color: black;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  border: 1px solid white;
  height: 45px;
  align-items: center;
  justify-content: center;
}

button:hover {
  color: black;
  background-color: white;
  border: 1px solid #595959;
}
button:focus {
  outline: 0;
}

.lightButton {
  background-color: transparent;
  color: #000000;
  border: none;
}

.lightButton:hover {
  color: #595959;
  background-color: transparent;
  border: none;
}

/* ===========================
LINKS
=========================== */

a {
  font-family: "Times New Roman", Times, serif;
  font-weight: "normal";
  color: #000000;
  font-size: 14px;
  text-decoration: none;
}

a:focus {
  border: none;
}

a:hover {
  color: #737373;
}

.active {
  color: #737373;
}

/* ===========================
COMMON
=========================== */

.title {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10%;
  margin-left: 10%;
  padding-top: 150px;
  width: 80%;
  margin-bottom: 100px;
}

.light-background {
  background-color: white !important;
}

.dark {
  color: rgba(0, 0, 0, 0.8) !important;
}

.dark:hover {
  color: #b1b1b1 !important;
}

label {
  font-size: 16px;
  line-height: 160%;
  font-weight: normal;
  margin: 0;
}

input {
  font-family: "Cormorant Garamond", serif;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
  outline: 0;
  border: 1px solid #000000;
  border-radius: 5px;
  background: white;
  color: #000000;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="checkbox"] {
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto !important;
  padding: 0;
  border: solid white;
  border-width: 0 2px 2px 0;
  /* transform: rotate(45deg); */
  background-color: #cccccc;
  width: 20px;
  height: 20px;
  margin: 3%;
}
input[type="checkbox"]:focus {
  background-color: #2aa1c0;
  /* #0E647D */
}
input[type="checkbox"]:checked {
  background-color: #2aa1c0;
}
/* INPUT CHECKBOX  */

/* END CHECKBOX */
.pureInput {
  margin: 3%;
  font-size: 17px;
  border: none;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
}
.pureInput:focus {
  outline: none;
}

select {
  margin-top: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* =========DIVIDER========== */

.horizontalDivider {
  width: 400px;
  height: 1px;
  background-color: #595959;
}

.lightDivider {
  width: 100%;
  background-color: #d0d0d0;
}

.verticalDivider {
  width: 1px;
  background-color: #595959;
}

.pointer {
  cursor: pointer;
}

/* ===========================
Header
=========================== */

.header {
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 100;
  border-bottom: 0.1rem solid #ccc;
}
.header-home {
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.1rem solid #ccc;
}

.logo {
  margin-left: 30px;
  margin-top: 10px;
}

.logo-home {
  width: 100%;
}

.logo-image-home {
  width: 210px;
}

.logo-image {
  width: 80px;
}

.nav-links {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-left: 10%;
  margin-right: 30px;
}
.nav-link-element {
  width: 135px;
}
.nav-links-home {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 5px 0px 15px 0px;
}

/* The dropdown container */
.dropdown {
  align-items: center;
  justify-content: center;
  float: left;
  overflow: hidden;
  z-index: 1000;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 134px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  justify-content: center;
}
/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

/* ===========================
Burger
=========================== */
.header-burger {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 115px;
  background-color: white;
  z-index: 99;
  justify-content: center;
  align-items: center;
}
.header-burger .nav-link-element {
  width: fit-content;
  max-height: 90px;
}

.logo-burger {
  width: 150px;
  height: auto;
  object-fit: contain;
  margin-top: 5px;
}

.burger-star {
  position: absolute;
  top: 40px;
  right: 36px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  color: #595959;
  opacity: 0.3;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #595959;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #595959;
}
.bm-cross:hover {
  background: black;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  height: 93% !important;
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.bm-burger-button {
  z-index: 100 !important;
}
/* ===========================
HomePage
=========================== */

.content-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slideshow-box {
  width: 100%;
}

.video-home {
  height: 80vh;
  object-fit: cover;
 
}
.ytp-chrome-top {
  display: none;
}

.ytp-button {
  display: none;
}

/* ===========================
SlideShow
=========================== */

.containerSlide {
  width: 100%;
  height: 80vh;
}

.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 7;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
}

/* ===========================
Footer
=========================== */

.footer {
  z-index: 20;
  margin-top: 30px;
  margin-bottom: 30px;
}
.socMedia {
  margin: 10px;
  font-size: 20px;
  padding: 10px;
}

/* ===========================
About
=========================== */

.about {
  display: flex;
  flex-direction: column;
}

.about-first-row {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.about-main-pic {
  object-fit: cover;
  width: 45%;
  margin-right: 5%;
}

.about-bloc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  margin-left: 5%;
}

.about-second-pic {
  object-fit: cover;
  width: 100%;
  height: 350px;
}

.about-text {
  margin-top: 0;
  text-align: justify;
}

.about-row {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.about-pic {
  width: 100%;
  object-fit: cover;
  max-height: 50vh;
  min-height: 450px;
}

/* =============================
CONTACT PAGE
============================= */

.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.contact.inputForm {
  width: 100%;
}
.contact-bloc {
  display: flex;
  flex-direction: column;
  margin: 2%;
  justify-content: space-between;
  text-align: left;
  width: 46%;
  height: 70vh;
}

.contactPic {
  width: 46%;
  margin: 2%;
  height: 70vh;
  object-fit: cover;
}

.inputForm {
  width: 90%;
  border: none;
  border-bottom: 1px solid black;
  margin: 0;
  margin-bottom: 30px;
}
.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contact-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contactButton {
  margin-top: 30px;
}
.contactInfo {
  padding-top: 50px;
  text-align: left;
}
.pressInfo {
  margin-bottom: 0;
}
.copyright {
  color: #595959;
}

/* =============================
CAMPAIGN PAGE
============================= */
.campaign-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 600px;
  grid-gap: 2rem;
}

.campaignCard {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.campaignCard-link {
  display: none;
}
.campaign-link {
  color: white;
}
.campaign-link:hover {
  color: white;
}
.campaignCard:hover .campaignCard-link {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  top: -605px;
  left: 0;
  color: white;
}

.campaign-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* =============================
CAMPAIGN PAGE - PICTURE
============================= */

.campaignPic {
  display: flex;
  flex-direction: column;
}

.campaignPicRange {
  display: flex;
}

.campaignPicVertical {
  width: 31.33%;
  object-fit: contain;
  margin: 1%;
}

.campaignPicVerticalKatabasis {
  width: 48%;
  object-fit: contain;
  margin: 1%;
}

.campaignPicHorizontal {
  width: 98%;
  object-fit: contain;
  margin: 1%;
}

.pointer {
  cursor: pointer;
}

.loader {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* =============================
POP IN Mesurement
============================= */

.popIn {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 101;
  overflow: hidden;
}

.closePopIn {
  width: 96%;
  text-align: end;
  padding: 2%;
  padding-bottom: 0;
  min-height: 40px;
}

.closePopInIcone {
  cursor: pointer;
}

.popIn-content {
  position: absolute;
  left: 30%;
  right: 30%;
  top: 20%;
  bottom: 20%;
  margin: auto;
  height: fit-content;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.measurementText {
  width: 70%;
}
.measurementPic {
  width: 250px;
  height: 250px;
  object-fit: contain;
}
.measurementTable {
  width: 70%;
  object-fit: contain;
  margin: 20px;
  margin-bottom: 30px;
}

/* =============================
Campaign ZOOM 
============================= */

.popUpCampaignPicture {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 101;
}

.fullScreenCampaignPic {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 5%;
  bottom: 5%;
  margin: auto;
  height: fit-content;
}

.fullScreenCampaignPic-content {
  width: 100%;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 103;
}

.slideCampaignCollec {
  height: 100%;
  max-width: 95%;
  max-height: 95vh;
  object-fit: contain;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
}

.closeCampaign {
  position: relative;
  color: #b1b1b1;
  top: -49%;
  right: 50px;
  cursor: pointer;
  content: "\f00d";
}

.closeCampaign:hover {
  color: white;
}

.campaignArrow {
  color: #b1b1b1;
  padding: 40px;
  cursor: pointer;
}

.campaignArrow:hover {
  color: white;
}

/* =============================
FULL SCREEN PICTURE
============================= */

.popUpPicture {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 101;
  overflow: hidden;
}

.fullScreenPic {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 5%;
  bottom: 5%;
  margin: auto;
  height: 90vh;
}

.fullScreenPic-content {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
}

.slidecollec {
  width: 90%;
  height: 80vh;
  object-fit: contain;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
}

.slidecollec-small {
  width: 54%;
}

.closeIcone {
  position: absolute;
  color: #b1b1b1;
  right: 20px;
  top: 15px;
  padding: 10px;
  cursor: pointer;
  content: "\f00d";
}

.closeIcone:hover {
  color: white;
}

.leftIcone {
  position: fixed;
  color: #b1b1b1;
  left: 8%;
  top: 48%;
  margin: 30px;
  cursor: pointer;
}

.leftIcone:hover {
  color: white;
}

.leftIconeSmall {
  cursor: pointer;
  position: relative;
  left: 5%;
  z-index: 100;
}

.leftIconeSmall:hover {
  color: #b1b1b1;
}
.rightIcone {
  position: fixed;
  color: #b1b1b1;
  right: 8%;
  top: 48%;
  cursor: pointer;
}

.rightIcone:hover {
  color: white;
}

.rightIconeSmall {
  cursor: pointer;
  position: relative;
  right: 5%;
  z-index: 100;
}

.rightIconeSmall:hover {
  color: #b1b1b1;
}

.zoom-small {
  display: flex;
  justify-content: center;
  width: 54%;
  padding: 3%;
}
.zoom {
  display: flex;
  width: 94%;
  padding: 3%;
}

.zoom-small.iiz  {
  width: 100%;
  height: 80vh;
  object-fit: contain;
}

.iiz__img {
  width: 100%;
  height: 80vh !important;
  object-fit: contain;
}
/* =============================
FULL SCREEN DETAILS
============================= */

.fullScreenDetails {
  text-align: left;
  padding: 3%;
  width: 40%;
}

.fullScreen-wishlistIconCard {
  position: absolute;
  top: 6%;
  left: 48%;
}
.colorRange {
  display: flex;
  margin-bottom: 20px;
}

.rounded-color {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  cursor: pointer;
}

.selected-color {
  outline: 1px solid black;
}

/* =============================
CUSTOM
============================= */
.glovesTypes {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
}
.glovesTypesBloc {
  cursor: pointer;
}

.disabledGlovesTypesBloc {
  cursor: pointer;
  opacity: 0.3;
}

.disabledGlovesTypesBloc:hover {
  opacity: 1;
}

.glovesTypes :hover {
  background-color: #e9e9e9;
}

.customGlovesPic {
  width: 200px;
  height: 300px;
  margin: 10px;
  object-fit: cover;
}

.customForm {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.customFormSection {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}
.customFormCategory {
  display: flex;
  flex-direction: column;
  width: 46%;
  margin: 2%;
  text-align: left;
}

.fabricColors {
  margin-top: 15px;
}

.colorBox {
  cursor: pointer;
}

.disabled {
  cursor: pointer;
  opacity: 0.3;
}

.disabled:hover {
  cursor: pointer;
  opacity: 1;
}

.colorPicture {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 6px;
}

.customAndInfoForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* =============================
CUSTOMCARD
============================= */

.customCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #e9e9e9;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.customCardTitle {
  margin: 20px;
}

.customCardSubField {
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.customCardInfo {
  margin-top: 40px;
  margin-bottom: 40px;
}

.customRequest {
  margin: 30px;
}

.customCardSection {
  width: 30%;
}

.customCardColorPicture {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.addCustomGloves {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* =============================
CLIENTFORM
============================= */

.clientForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.clientFormInputRange {
  display: flex;
  align-items: center;
}

.clientFormInput {
  display: flex;
  width: 100%;
  align-items: center;
}

.clientFormCheckbox {
  display: flex;
  width: 100%;
  align-items: center;
}

.addBillingAddress {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 30px;
  margin-top: 30px;
}
.clientFormButton {
  margin: 40px;
}

#newsletter {
  margin-right: 15px;
  font-size: 17px;
}
/* =============================
QUANTITY INPUT
============================= */

#quantity {
  text-align: center;
}

.quantityIconPlus {
  color: #595959;
  cursor: pointer;
  margin-left: 10px;
}

.quantityIconMinus {
  color: #595959;
  cursor: pointer;
  margin-right: 10px;
}

.quantityicon:hover {
  color: #7b7b7b;
}

/* =============================
COLLECTION
============================= */
.collectionRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 100px;
}

.collectionCard {
  display: flex;
  flex-direction: column;
  width: 21%;
  height: 465px;
  padding: 2%;
  padding-bottom: 0;
  cursor: pointer;
}

.collectionCard span {
  height: 100%;
}

.collectionPicture {
  position: relative;
  width: 100%;
  height: 415px;
  object-fit: cover;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
  cursor: pointer;
}

.collection-wishlistIconCard {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
  align-items: flex-end; */
  align-items: flex-end;
  /* height: 350px;
  width: 16.8%;
  height: 465px; */
  width: 16.8%;
  color: black;
  z-index: 3;
}

.collection-wishlistIcon {
  padding: 30px;
  padding-top: 3%;
  cursor: pointer;
}

/* =============================
WISHLIST
============================= */
.wishlistCard {
  display: flex;
  flex-direction: column;
  width: 21%;
  min-height: 610px;
  height: fit-content;
  padding: 2%;
  padding-bottom: 0;
  cursor: pointer;
}
.wishlistCard span {
  height: 100%;
}
.wishlistCard .select {
  z-index: 6;
}
.wishlistCard .select span {
  height: auto;
}
/* =============================
OrderSummary
============================= */
.summary-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
}
.total {
  color: black;
  font-size: 16px;
}
.summary-card-left {
  display: flex;
  align-items: center;
  width: 150px;
}

.summary-image {
  width: 50px;
}

.summary-sub-info  {
  display: flex;
  flex-direction: column;
}

.summary-content {
  position: absolute;
  left: 30%;
  right: 30%;
  top: 5%;
  bottom: 5%;
  margin: auto;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.summary-contact {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
}

.summary-info {
  width: 100%;
  padding-top: 50px;
  text-align: left;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.summary-product {
  overflow: "scroll";
  -webkit-overflow-scrolling: touch;
  width: 80%;
}

/* =============================
ThanksForOrder
============================= */
.confirm {
  height: 80vh;
  align-items: center;
}
.confirmation-image {
  padding-top: 50px;
  position: absolute;
  width: 100%;
  opacity: 0.5;
  z-index: 3;
  height: 100%;
  object-fit: cover;
}

.confirmation-content {
  z-index: 7;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .confirmation-text {
  padding: 70px;
  padding-top: 160px;
  padding-bottom: 160px;
  border: 2px solid white;
} */

/* ===========================
BREAKPOINTS
=========================== */

@media only screen and (min-width: 1920px) {
  .content {
    margin-right: 10%;
    margin-left: 10%;
    width: 80%;
  }
  /* COLLECTION */
  .collectionCard {
    width: 16%;
    margin-bottom: 30px;
  }
  .collection-wishlistIconCard {
    width: 13.8%;
  }
  .collectionPicture {
    height: 500px;
  }
  /* WISHLIST */
  .wishlistCard {
    width: 16%;
  }
}

@media only screen and (max-width: 1200px) {
  /* COLLECTION */
  .collectionCard {
    width: 29%;
    margin-bottom: 30px;
  }
  .collection-wishlistIconCard {
    width: 24.8%;
  }

  /* WISHLIST */
  .wishlistCard {
    width: 29%;
  }
}

@media only screen and (max-width: 1024px) {
  /* COLLECTION */
  .collectionCard {
    width: 46%;
    margin-bottom: 30px;
    height: fit-content;
  }
  .collection-wishlistIconCard {
    width: 37%;
  }
  .collectionPicture {
    height: 500px;
  }

  /* WISHLIST */
  .wishlistCard {
    width: 46%;
    margin-bottom: 30px;
  }

  .clientFormInput {
    flex-direction: column;
  }

  .clientFormCheckbox {
    justify-content: center;
    margin-top: 30px;
  }

  /* CAMPAIGN ZOOM*/
  .slideCampaignCollec {
    width: 100%;
    height: auto;
  }
  .campaignArrow {
    display: none;
  }
  .closeCampaign {
    position: fixed;
    top: 20px;
    right: 20px;
  }
}

@media only screen and (max-width: 850px) {
  a {
    font-size: 16px;
  }
  .content-home {
    padding-top: 113px;
  }
  .content {
    margin-bottom: 30px;
  }
  /* ABOUT */
  .about-first-row {
    flex-direction: column-reverse;
    /* max-height: none; */
  }

  .about-main-pic {
    width: 100%;
    margin-right: 0;
  }
  .about-second-pic {
    margin-bottom: 30px;
  }
  .about-bloc {
    flex-direction: column-reverse;
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }
  .about-text {
    margin-bottom: 30px;
  }
  /* CAMPAIGN */
  .campaign-page {
    grid-template-columns: 1fr;
  }
  .campaignCard-link {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    top: -605px;
    left: 0;
    color: white;
  }
  .campaignPicRange {
    display: flex;
    flex-direction: column;
  }
  .campaignPicVertical {
    width: 100%;
  }

  .campaignPicVerticalKatabasis {
    width: 100%;
  }

  .campaignPicHorizontal {
    width: 100%;
  }

  /* FULLSCREEN PICTURE */
  .slidecollec {
    height: 60vh;
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .fullScreenPic-content {
    flex-direction: column;
  }
  .fullScreenPic {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .zoom-small {
    width: 100%;
  }
  .fullScreenDetails {
    width: 90%;
    padding-bottom: 120px;
  }
  /* SIZE GUIDE */
  .popIn-content {
    left: 5%;
    top: 5%;
    right: 5%;
    bottom: 5%;
  }

  .leftIconeSmall {
    top: 48%;
  }
  .rightIconeSmall {
    top: 48%;
  }

  /* ORDER SUMMARY */
  .summary-content {
    left: 5%;
    top: 5%;
    right: 5%;
    bottom: 5%;
  }
  .summary-product {
    overflow: none;
  }
  .closePopInIcone {
    position: fixed;
    right: 10%;
    top: 8%;
  }
  .summary-info {
    padding-top: 10px;
  }

  /* CONTACT */
  .contact {
    flex-direction: column;
  }
  .contactPic {
    display: none;
  }
  .contact-bloc {
    width: 100%;
    margin: 0;
    flex-direction: column;
    height: auto;
  }
  .contactInfo {
    padding-top: 30px;
  }
  .contact-form {
    align-items: center;
  }

  /* THANKS FOR ORDER */
}

@media only screen and (max-width: 900px) {
  .comingSoonLogo {
    width: 65%;
  }
}
@media only screen and (max-width: 600px) {
  .comingSoonLogo {
    width: 80%;
  }
  /* COLLECTION */
  .collectionCard {
    width: 100%;
    margin-bottom: 30px;
  }
  .collectionPicture {
    height: 600px;
  }
  .collection-wishlistIconCard {
    width: 80%;
  }
  .collectionRow {
    flex-direction: column;
    margin-bottom: 0;
  }
  /* WISHLIST */

  .wishlistCard {
    width: 100%;
  }
}
